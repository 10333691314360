<template>
  <div class="allclass" v-wechat-title="$route.meta.title">
    <navigation :msg="msg" />
    <p class="gpc">{{ company }}</p>
    <p class="gpc qrcla">{{ qrName }}</p>
    <div>
      <div class="qrborder" id="qrcodeid">
        <!-- <div id="qrcode"></div> -->
        <qrcode
          :url="url"
          :iconurl="icon"
          :wid="232"
          :hei="232"
          :imgwid="40"
          :imghei="36"
        ></qrcode>
      </div>
    </div>
    <p id="pp2" class="pp">请截图保存此页面</p>
    <p class="pp">向候选人发送/出示二维码</p>
    <p class="pp">候选人扫码</p>
    <p class="pp">即可进行背景调查</p>
    <button @click="getall" class="bton bt">点击下载二维码</button>
    <div class="titleclass">微信内置浏览器已禁止下载，请在外部浏览器打开。</div>
    <canvas id="canvases" width="232" height="332"> </canvas>
    <!-- <button @click="getall">获取所有</button> -->
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
  </div>
</template>

<script>
// import QRCode from "qrcodejs2"; // 引入qrcode
import qrcode from "vue_qrcodes";
import base from "../../../util/base";
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
import { Notify } from "vant";

export default {
  name: "test",
  components: {
    qrcode,
    sharepage,
    share,
  },
  data() {
    return {
      msg: "预览二维码",
      company: "", //企业名称
      url: "",
      icon: require("../../assets/users3.png"),
      qrName: "二维码名称",
      imgurl1: "",
      imgurl2: "",
    };
  },
  created() {
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      if (sharemm.isshare && sharemm.isshare == 1) {
        this.company = sharemm.company;
        this.qrName = sharemm.qrName;
        this.url = sharemm.url; // 二维码地址
      }
    } else {
      this.company = this.$route.query.company;
      this.qrName = this.$route.query.qrName;
      this.url = base.url + "/transit?qrId=" + this.$route.query.qrId; // 二维码地址
    }
    this.clickshare();
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
  methods: {
    clickshare() {
      let obj = JSON.stringify({
        sharetitle: "授权二维码",
        sharedesc: "请扫码完善信息，完成背景调查授权。",
        isshare: "1",
        url: this.url,
        company: this.company,
        qrName: this.qrName,
        share_phone:localStorage.getItem("phone")
      });
      localStorage.setItem("shareobj", obj);
    },
    // /**
    //  * @deprecated 下载文件
    //  * @param {string} url
    //  * @param {string} filename
    //  */
    // handleFileDownload(url, filename) {
    //   // 创建 a 标签
    //   let a = document.createElement("a");
    //   a.href = url;
    //   a.download = filename;
    //   a.click();
    // },

    // /**
    //  * @deprecated 处理 pdf url，使其不在浏览器打开
    //  * @param {string} url
    //  */
    // handlePdfLink(url, filename) {
    //   fetch(url, {
    //     method: "get",
    //     responseType: "arraybuffer",
    //   })
    //     .then(function (res) {
    //       if (res.status !== 200) {
    //         return res.json();
    //       }
    //       return res.arrayBuffer();
    //     })
    //     .then((blobRes) => {
    //       // 生成 Blob 对象，设置 type 等信息
    //       const e = new Blob([blobRes], {
    //         type: "application/octet-stream",
    //         "Content-Disposition": "attachment",
    //       });
    //       // 将 Blob 对象转为 url
    //       const link = window.URL.createObjectURL(e);
    //       this.handleFileDownload(link, filename);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },

    getall() {
      var aaa = this.qrcodemeth(this);
      aaa.downloadimg();
    },
    saveQrcode() {
      this.download();
      //window.print();
    },
    download() {
      // let qrcode = new QRCode("qrcode", {
      //   width: 132,
      //   height: 132,
      //   text:
      //   colorDark: "#000",
      //   colorLight: "#fff",
      //   src: "../../assets/add.png"
      // });
    },

    //测试pdf
    download2() {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");

      var img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        context.drawImage(this, 0, 0);
      };
      img.src =
        base.formal + "/pdf/20200923/a86a8d5de87ea070e4cc5cbf39d2bb86.pdf";
      // var request = new XMLHttpRequest(); // 新建XMLHttpRequest对象

      // request.onreadystatechange = function (res) {
      //
      //   // 状态发生变化时，函数被回调
      //   if (request.readyState === 4) {
      //     // 成功完成
      //     // 判断响应结果:
      //     if (request.status === 200) {
      //       // 成功，通过responseText拿到响应的文本:
      //       return success(request.responseText);
      //     } else {
      //       // 失败，根据响应码判断失败原因:
      //       return fail(request.status);
      //     }
      //   } else {
      //     // HTTP请求还在继续...
      //   }
      // };

      // // 发送请求:
      // request.open("GET", "file:///C:/Users/admin/Desktop/a86a8d5de87ea070e4cc5cbf39d2bb86.pdf");
      // request.send();

      // alert("请求已发送，请等待响应...");
    },

    qrcodemeth(vue) {
      var canvas = document.getElementById("canvases");
      
      var canvas2 = document
        .getElementsByTagName("canvas")[0]
        .toDataURL("image/png");
      var ctx = canvas.getContext("2d"),
        alldom,
        imgarray,
        imgss;
      ctx.font = "20px serif";
      ctx.textAlign = "center";
      ctx.fillText(this.company, canvas.width / 2, 50);

      ctx.font = "20px serif";
      ctx.textAlign = "center";
      ctx.fillText(this.qrName, canvas.width / 2, 80);
      var img = new Image();
      var img2 = new Image();
      var drawImage = function () {
        img.onload = () => {
          ctx.drawImage(img, 0, 100);
        };
        img2.onload = () => {
          ctx.drawImage(img2, 232 / 2 - 25, 190, 50, 46);
        };

        img.src = canvas2;
        img2.src = require("../../assets/users3.png");
      };
      var init = function () {
        alldom = document.getElementById("qrcodeid");
        imgarray = [];
        let aa = alldom.getElementsByTagName("img");

        for (let i = 0; i < alldom.childNodes.length; i++) {
          imgss = alldom.childNodes[i].lastElementChild.lastElementChild.src;
        }
      };
      async function downloadimg() {
        vue.$toast.loading("请稍后");
        let wx = navigator.userAgent.toLowerCase();
        if (wx.match(/MicroMessenger/i) == "micromessenger") {
          Notify({
            type: "success",
            message: "微信已禁止下载，请在外部浏览器打开。",
          });
          return;
        }
        init();
        drawImage();

        setTimeout(() => {
          // let a = document.createElement("a");
          // let event = new MouseEvent("click");
          // a.download = "二维码下载.png";
          // a.href = canvas.toDataURL("image/png");
          // a.dispatchEvent(event);

          let a = document.createElement("a");
          let event = new MouseEvent("click");
          a.download = "二维码下载.png";
          let base64msg = canvas.toDataURL("image/png");
          let base = base64msg.split(",")[1];
          let base2 = window.atob(base);
          let byte = new Uint8Array(base2.length);
          for (var i = 0; i < base2.length; i++) {
            byte[i] = base2.charCodeAt(i);
          }

          // 生成Blob对象（文件对象）
          let imgbolb = new Blob([byte], { type: "image/png" });
          let urls = URL.createObjectURL(imgbolb);
          a.href = urls;
          a.dispatchEvent(event);
        }, 1500);
      }
      return { init, drawImage, downloadimg };
    },
  },
};
</script>
<style scoped>
.allclass{
  min-height: 100%;
}
#pp2 {
  font-size: 0.3rem;
  margin-bottom: 0.3rem;
}
.titleclass {
  text-align: center;
  margin-top: 0.24rem;
  padding-bottom: 1rem;
  font-size: 0.26rem;
  line-height: 0.4rem;
  color: #f55613;
}
.gpc {
  text-align: center;
  margin-top: 0.75rem;
  text-align: center;
  font-size: 0.36rem;
  line-height: 0.62rem;
  font-weight: 500;
}
#canvases {
  display: none;
  margin-left: 200px;
}
.gpc + div {
  display: flex;
  justify-content: center;
  margin-top: 0.18rem;
  margin-bottom: 0.59rem;
}
p {
  text-align: center;
}
.pp {
  font-size: 0.26rem;
  font-weight: 500;
  line-height: 0.4rem;
  color: #000000;
}
.bt {
  margin-top: 2.25rem;
}
.qrborder {
  display: inline-block;
  padding: 0.1rem;
  border: 1px solid #b6b5b5;
}
.qrcla {
  margin-top: 0.2rem;
}
</style>